import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const DevRenderer = () => {
  const { id, tenant } = useParams();
  if (!tenant && !id) return <div>Not Found</div>;

  return (
    <div>
      <Helmet>
        <script src="https://form-renderer-dev2.vercel.app/form.embed.js" />
      </Helmet>

      <div data-aroopa-form-id={id} data-aroopa-tenant={tenant}></div>
    </div>
  );
};

export default DevRenderer;
