import React, { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    window.location.replace("https://www.aroopaapps.com/aroopa-forms/");
  }, []);

  return <div>Aroopa Forms</div>;
};

export default Home;
