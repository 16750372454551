import { Route, Routes } from "react-router-dom";
import RenderForm from "./RenderForm";
import Render from "./Render";
import Home from "./Home";
import DevRenderer from "./DevRenderer";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />}></Route>

        <Route path="/:id" exact element={<RenderForm />}></Route>
        <Route path="/:tenant/:id" exact element={<Render />}></Route>
        <Route
          path="/development/:tenant/:id"
          exact
          element={<DevRenderer />}
        ></Route>
      </Routes>
    </>
  );
}

export default App;
